// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";



.sidebar {
   // background: #015174;
   background: #015174;;
  
}

header.app-header.navbar {
    background: #ffffff;
    
}

.navbar-nav .nav-link {
    color: #100101 !important;
}


.sidebar .nav-link .nav-icon {
    color: #111e28;
}
.sidebar .nav-link:hover{
    color: #ffffff;
    background: #111e28;
}


i.fa.fa-2x {
    color: #1a539c;
}
.btn-primary {
    color: #fff;
    background-color: #015174;
    border-color: #015174;
}

.btn-outline-primary {
    color: #015174;
    border-color: #015174;
}
.btn-primary:hover {
    color: #fff;
    background-color: #321fdb;
    border-color: #321fdb;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/*
input[type=number] {
  -moz-appearance: textfield;
}
*/
input[type="radio"], input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 1;
    background: white;
    height: 16px;
    width: 16px;
    border: 1px solid #015174;
  }
  
  input[type="radio"]:checked, input[type="checkbox"]:checked {
    background: #015174;
  }
  
  input[type="checkbox"]:hover {
    filter: brightness(90%);
  }
  
  input[type="checkbox"]:disabled {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
  }
  
  input[type="radio"]:after, input[type="checkbox"]:after {
    content: '';
    position: relative;
    left: 40%;
    top: 20%;
    width: 30%;
    height: 60%;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: none;
  }
  
  input[type="radio"]:checked:after, input[type="checkbox"]:checked:after {
    display: block;
  }
  
  input[type="radio"]:disabled:after {
    border-color: #7b7b7b;
  }




















